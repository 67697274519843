import React from "react";
import { Link } from "gatsby";
import { Carousel, Grid } from "antd";
import BannerImage from "../images/Ocean-beach-pool_1860x446.png";

export const HomeBanner = (props) => {
  const contentStyle = {
    width: "100%",
    "object-fit": "cover",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  console.log(props);

  return (
    <Carousel autoplay>
      <div>
        <img src={BannerImage} style={contentStyle} />
      </div>
    </Carousel>
  );
};
